<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png') z-index: 0 !important;"
      >
      <video ref="videoRef"  loop="loop" muted="muted" class="video" ></video>
      </parallax>
    </div>
    <n-button type="button" class="btn btn-icon btn-round btn-primary" @click="OnMute" style="z-index: 10;position: relative; background-color: transparent; top: -50px; left: 0%;">
      <i v-if="muted" class="now-ui-icons" ><b-icon icon="volume-mute"></b-icon></i>
      <i v-else class="now-ui-icons" ><b-icon icon="volume-down"></b-icon></i>
    </n-button>
      
    <div class="content-center brand" style="text-align:center">
      <img class="n-logo" src="/img/redpill.png" alt="" />
      <h1 class="h1-seo" style="font-weight: bold; margin-top: -20px; color: white;">Red Pill Lab</h1>
      <h3 style=" margin-top: -20px; color: white;">{{$t('index.slogan')}}</h3>
    </div>
    <div class="content-center brand" style="text-align:center">
      <br><br>
      <h3 style=" margin-top: -20px; color: white;">{{$t('index.tagline')}}</h3>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">Red Pill Go</h2>
            <img src="/img/rpg.png">
            <br><br>
            <h4 class="description" style="color: white;">{{$t('index.redpillgo')}}</h4>
          </div>
        </div>
      </div>
      <div class="container" style="text-align:center">
        <n-button type="primary" round size="lg" @click="$router.push(`/${$i18n.locale}/redpillgo`)">{{$t('index.learn')}}</n-button>
      </div>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">Red Pill Studio</h2>
            <img src="/img/rps_ui.png">
            <br><br>
            <h4 class="description" style="color: white;">{{$t('index.redpillstudio')}}</h4>
          </div>
        </div>
      </div>
      <div class="container" style="text-align:center">
        <n-button type="primary" round size="lg" @click="$router.push(`/${$i18n.locale}/redpillstudio`)">{{$t('index.learn')}}</n-button>
      </div>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">Red Pill Voice Engine</h2>
            <img src="/img/VoiceEngineBB.png">
            <br><br>
            <h4 class="description" style="color: white;">{{$t('index.redpillvoiceengine')}}</h4>
          </div>
        </div>
      </div>
      <div class="container" style="text-align:center">
        <n-button type="primary" round size="lg" @click="$router.push(`/${$i18n.locale}/voiceengine`)">{{$t('index.learn')}}</n-button>
      </div>
    </div>
  
    <br><br>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('index.news_label')}}</h2>
            <img src="/img/MegaGrantsPoster.png">
            <br><br>
            <h3 class="title" style="color: white;">{{$t('index.news[0].title')}}</h3>
            <h4 class="description" style="color: white;">{{$t('index.news[0].text')}}</h4>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-md-8 ml-auto mr-auto text-center">
      <h2 class="title" style="color: white;">{{$t('index.partners')}}</h2>
    </div>
    <carousel-section></carousel-section>

  
  </div>
</template>
<script>

import { Parallax, Button } from '@/components';
// import RPGSurvey from '@/components/RPGSurvey.vue'
//import ProgressPagination from './components/ProgressPagination';
import CarouselSection from './components/CarouselSection'
export default {
  name: 'index',
  bodyClass: 'landing-page',
  mounted() {
      this.$refs.videoRef.src = "/img/RPGPromo.mp4";
      this.$refs.videoRef.play();
      if(localStorage.getItem('surveyCompleted')==='true')
        this.showSurvey = false;
      
  },
  methods: 
  {

    OnMute()
    {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.$refs.videoRef.play();
      this.muted = this.$refs.videoRef.muted;
    }

  },
  data() {
    return {
      // showSurvey: true,
      expandSurvey: false,
      muted: true
    };
  },
  components: {
    Parallax,
    // RPGSurvey,
    [Button.name]: Button,
    CarouselSection,
    //ProgressPagination,
  }
};
</script>
<style>
video {
  position: absolute;
  height: 100%;
  top: 0%;
  left: 50%;

  -ms-transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 1;

} 
</style>
