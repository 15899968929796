<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png')"
      >
      </parallax>
      </div>
      
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('download.title')}}</h2>
          </div>
        </div>
        <div id="feature">
          <div class='column' id="main">
              <img src="/img/RPG_APP.jpg">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;"> {{$t('download.items[0].title')}}</h4>
                <p>{{$t('download.items[0].platform')}}</p>
                <div class="container" style="text-align:center">
                  <n-button type="primary" round size="lg" @click="OnDownload($t('download.items[0].link'))">{{$t('navbar.downlaod')}}</n-button>
                </div>
            </div>
        </div>
        <div id="feature">
          
          <div class='column' id="side">
              <h4 class="title" style="color: white;"> {{$t('download.items[4].title')}}</h4>
              <p>{{$t('download.items[4].platform')}}</p>
              <div class="container" style="text-align:center">
                <n-button type="primary" round size="lg"  onclick="location.href='https://play.google.com/store/apps/details?id=com.next.redpillgo'">{{$t('navbar.downlaod')}}</n-button>
              </div>
          </div>
          <div class='column' id="main">
            <img src="/img/rpg_land_track.png">
          </div>
        </div>
        <div id="feature">
          <div class='column' id="main2">
            <img src="/img/UnityLogo.png">
          </div>
          <div class='column' id="side2">
              <h4 class="title" style="color: white;">{{$t('download.items[1].title')}}</h4>
              <p>{{$t('download.items[1].platform')}}</p>
              <div class="container" style="text-align:center">
                <n-button type="primary" round size="lg" @click="OnDownload($t('download.items[1].link'))">{{$t('navbar.downlaod')}}</n-button>
              </div>
          </div>
            
        </div>
        <div id="feature">
          
          <div class='column' id="side">
              <h4 class="title" style="color: white;">{{$t('download.items[2].title')}}</h4>
              <p>{{$t('download.items[2].platform')}}</p>
              <div class="container" style="text-align:center">
                <n-button type="primary" round size="lg" @click="OnDownload($t('download.items[2].link'))">{{$t('navbar.downlaod')}}</n-button>
              </div>
          </div>
          <div class='column' id="main">
            <img src="/img/RPG_Unity_Demo.jpg">
          </div>
        </div>
        <div id="feature">
          <div class='column' id="main2">
            <img src="/img/rps_ui.png">
          </div>
          <div class='column' id="side2">
              <h4 class="title" style="color: white;">{{$t('download.items[3].title')}}</h4>
              <p>{{$t('download.items[3].platform')}}</p>
              <div class="container" style="text-align:center">
                <n-button type="primary" round size="lg" @click="OnDownload($t('download.items[3].link'))">{{$t('navbar.downlaod')}}</n-button>
              </div>
          </div>
            
        </div>
      </div>
     
    </div>
    
  <br><br>
  <iframe id="my_iframe" style="display:none;"></iframe>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'voice-engine',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  methods: 
  {

    OnMute()
    {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.$refs.videoRef.play();
      this.muted = this.$refs.videoRef.muted;
    },
    OnDownload(url)
    {
      document.getElementById('my_iframe').src = url;

    }

  },
  data() {
    return {
      muted: true
    };
  }
};
</script>
<style>
 @import '../assets/scss/redpill.scss';
</style>
